import React, { Fragment, useState } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { components, SingleValueProps } from "react-select";

import MultySelect from "./MultySelect";
import {
    SetQuestionName,
    SetQuestionType,
    SetEditPoint,
    SetCorrectAnswer,
    SetOptionName,
    SetPoint,
    AddAnswer,
    DeleteAnswer,
    DeleteQuestion
} from "../store/domain/domain.actions";
import { domainSelectors } from "../store/domain/domain.selectors";
import { domain as types } from "../types";

import { MultySelectStyle } from "../styles/MultySelectStyle";
import {
    typeQuestionList
} from "../api/optionsSelect";
import { uuidv4 } from "../config/utils";

const upIcon = require("./../images/dropDownSelected.svg");
const downIcon = require("./../images/dropDownIconCommon.svg");
const deleteIcon = require("./../images/deleteIcon.svg");
const radioButton = require("./../images/radioButton.svg");
const textIcon = require("./../images/textIcon.svg");
const successQuestion = require("./../images/successQuestion.svg");
const cross = require("./../images/icon/cross.svg");



const Questions = (props: types.QuestionType) => {
    const dispatch = useDispatch();
    const { questions } = useSelector(
        domainSelectors.getAllState
    );
    const { id, isEditPoint, index, name, type, score, length } = props;

    const DropdownIndicator = props => {
        return (
            components.DropdownIndicator && (
                <components.DropdownIndicator {...props}>
                    <img src={props.selectProps.menuIsOpen ? upIcon : downIcon} alt="up" />
                </components.DropdownIndicator>
            )
        );
    };

    const currentIcon = (value) => {
        if (value === 'multiple') {
            return <input width={12} type="checkbox" className="checkbox-checked" checked={true} />
        } else if (value === 'single') {
            return <img width={10} className='domain-modal__radio-button' src={radioButton} />
        } else if (value === 'text') {
            return <img width={10} className='domain-modal__radio-button' src={textIcon} />
        }
    }

    const Option = (props) => {
        return (
            <div className="domain-modal__multySelectorOption">
                <components.Option {...props}>
                    <label className="form-control empty-mb">
                        {currentIcon(props.value)}
                        <span>{props.label}</span>
                    </label>
                </components.Option>
            </div>
        );
    };

    const SingleValue = ({
        children,
        ...props
    }: SingleValueProps) => (
        <components.SingleValue {...props}>
            <div className='form-control' >
                {currentIcon(type)}{children}
            </div>
        </components.SingleValue>
    );



    const buttonAnswer = (type, id, isCorrect) => {
        if (type !== 'text') {
            return (
                <div className='checkbox__point__wrapper'>
                    {isCorrect ? <img className="point__icon mg-icon" src={successQuestion} alt="success question" /> : null}
                    {
                        isEditPoint === false ?
                            <button onClick={() => removeAnswer(id, index)}>
                                <img className="point__icon" src={cross} alt="remove answer" />
                            </button>
                            : null
                    }
                </div>
            )
        }
    }

    const handleAddAnswer = (index) => {
        if (isEditPoint === false) {
            const answerData = { "id": uuidv4(), "isCorrect": false, "score": 0, "name": "Answer" };
            const data = [{
                answerData,
                index
            }]
            dispatch(AddAnswer(data))
        }
    }

    const removeAnswer = (id, indexArray) => {
        if (isEditPoint === false) {
            const data = [{
                id,
                indexArray
            }]
            dispatch(DeleteAnswer(data))
        }
    }

    const handleRemoveQuestion = (id) => {
        if (isEditPoint === false) {
            const data = [{
                id
            }]
            dispatch(DeleteQuestion(data))
        }
    }

    const changeOptionName = (e, id, element) => {
        const data = [{
            value: e.target.value,
            id,
            element,
            index
        }]
        dispatch(SetOptionName(data))
    }



    const optionHandler = (type, questions, index, point) => {
        if (questions && questions.length === 0) {
            return;
        }
        if (questions && questions.length && type !== 'text') {
            return (
                <div className={`radio__wrapper ${type !== 'text' ? 'mgLeft' : null} `}>
                    {questions[index].answers.map((item, el) => {
                        if (type === 'single') {
                            return (
                                <Fragment key={item.id}>
                                    <input type="radio" id={`${item.id}`} className="radio-input" name="radio-group" checked={item.isCorrect} onChange={e => changeAnswer(e, el)} />
                                    <label htmlFor={`${item.id}`} className={`radio-label ${item.isCorrect ? 'checked' : null} ${point && item.isCorrect ? 'bg-color' : ''}`} >
                                        <span className={`radio-border ${item.isCorrect ? 'checked' : null}`}></span>
                                        {
                                            isEditPoint ? item.name : <input type="text" className='checkbox__input__value' value={item.name} onChange={e => changeOptionName(e, item.id, el)} />
                                        }
                                        <div>
                                            {buttonAnswer(type, item.id, item.isCorrect)}
                                        </div>
                                    </label>
                                </Fragment>
                            )
                        } else if (type === 'multiple') {
                            return (
                                <Fragment key={item.id}>
                                    <label className="checkbox__wrapper">
                                        <div className="checkbox__input">
                                            <input type="checkbox" className="checkbox-checked"
                                                onChange={e => changeAnswer(e, el)} checked={item.isCorrect} />
                                            {
                                                isEditPoint ? <span>{item.name}</span> : <span><input className='checkbox__input__name' type="text" value={item.name} onChange={e => changeOptionName(e, item.id, el)} /></span>
                                            }

                                        </div>
                                        {
                                            isEditPoint ? <div className='checkbox__point__wrapper'>
                                                {buttonAnswer(type, item.id, item.isCorrect)}
                                                <input className='point__range' min={0} max={10} type="number" value={item.score} onChange={e => changePointValue(e, index, type, el)} />
                                                <span>/10</span>
                                            </div> : buttonAnswer(type, item.id, item.isCorrect)
                                        }
                                    </label>
                                </Fragment>
                            )
                        }
                    })}
                    {type === 'multiple' &&
                        <label className="form-control margin-bottom" onClick={() => handleAddAnswer(index)}>
                            <input type="checkbox" className="checkbox-checked"
                                disabled={true} />
                            <span style={{ color: '#c1c5cb' }}>Add option</span>
                        </label>
                    }
                    {
                        type === 'single' &&
                        <>
                            <input type="radio" className="radio-input" name="radio-group" />
                            <label className="radio-label" onClick={() => handleAddAnswer(index)}><span className="radio-border"></span>Add option</label>
                        </>
                    }
                </div>)
        } else {
            return (
                <div className={`radio__wrapper ${type !== 'text' ? 'mgLeft' : null} `}>
                    <textarea
                        onChange={e => changeAnswer(e, index)}
                        value={questions[index].answers}
                        className="textarea-style"
                        id="w3review"
                        name="w3review"
                        rows={4}
                        placeholder='Type answer'
                    />
                </div>)

        }
    }

    const changeAnswer = (e, inputIndex) => {
        if (isEditPoint) {
            const type = e.target.type === 'textarea' ? 'text' : e.target.type;
            const data = [{
                index,
                id: inputIndex,
                value: e.target.value,
                type
            }];
            dispatch(SetCorrectAnswer(data))
        }
    }

    const handleChangeType = (selected) => {
        let answers;
        if (selected.value === 'text') {
            answers = '';
        } else if (selected.value === 'single') {
            answers = [{ "id": uuidv4(), "isCorrect": false, "score": 0, "name": "1 year" }, { "id": uuidv4(), "isCorrect": false, "score": 0, "name": "1-3 years" }];
        } else {
            answers = [{ "id": uuidv4(), "isCorrect": false, "score": 0, "name": "1 year" }, { "id": uuidv4(), "isCorrect": false, "score": 0, "name": "1-3 years" }];
        }
        dispatch(SetQuestionType([{ type: selected.value, index: index, answers }]));
    }

    const editPoint = (index, value) => {
        const data = {
            index,
            value
        }
        dispatch(SetEditPoint([data]))
    }

    const changePointValue = (e, index, type, element = 0) => {        
        const value = +e.target.value > 10 ? 10 : +e.target.value;
        if (isEditPoint) {
            const data = [{
                value,
                index,
                type,
                element
            }]
            dispatch(SetPoint(data))
        }
    }


    return (
        <div className="domain-modal__select">
            <div>
                <label className="label-name-font" htmlFor="label-name-font">
                    <span>
                        Domain Question {index + 1}<span className="domain-modal__required">*</span>
                    </span>

                    {
                        length > 1 ?
                            <button onClick={() => handleRemoveQuestion(id)}>
                                <img src={deleteIcon} alt="delete question" />
                            </button> :
                            null
                    }

                </label>
                {
                    isEditPoint && type !== 'multiple' ?
                        <div className='point__wrapper'>
                            <span className='point__wrapper__content' >
                                {name}
                            </span>
                            <div className='point__wrapper__value' >
                                <input className='point__range' min={0} max={10} type="number" value={score} onChange={e => changePointValue(e, index, type)} />
                                <span>/10</span>
                            </div>

                        </div>
                        : isEditPoint ?
                            <div className='point__wrapper'>
                                <span className='point__wrapper__content' >
                                    {name}
                                </span>
                            </div> :
                            <textarea
                                className='live-task-input'
                                value={name}

                                placeholder="Enter question title"
                                style={{ 'resize': 'none' }}
                                onChange={(e) => {
                                    dispatch(SetQuestionName([{ name: e.target.value, index }]))
                                }}
                            />

                }
            </div>
            <div className="domain-modal__multySelector">
                {
                    isEditPoint === false &&
                    (<MultySelect
                        openMenuOnClick={true}
                        options={typeQuestionList}
                        menuPlacement='bottom'
                        placeholder='Choose type question'
                        hideSelectedOptions={false}
                        components={{ Option, DropdownIndicator, SingleValue, IndicatorSeparator: () => null }}
                        onChange={handleChangeType}
                        value={typeQuestionList.find(item => item.value === type) as object}
                        styles={MultySelectStyle}
                    />)
                }

                {optionHandler(type, questions, index, isEditPoint)}
                {
                    isEditPoint ?
                        <button onClick={() => editPoint(index, false)} className="point__done">
                            Done
                        </button> :
                        <button onClick={() => editPoint(index, true)} className="point__wrapper">
                            <img className="point__icon" src={successQuestion} alt="success question" />
                            <span className="point__text">Answer Key</span>
                            <span className="point__value">( {score} / 10 point)</span>
                        </button>
                }
            </div>
        </div >
    )
};

export default Questions;