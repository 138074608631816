import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { components } from "react-select";
import axios from 'axios'
import { navigate } from "@reach/router";

import { SetName, SetIndustry, SetDepartment, SetStep, ClearQuestion } from "../store/domain/domain.actions";
import { domainSelectors } from "../store/domain/domain.selectors";

import MultySelect from "./MultySelect";
import { MultySelectStyle } from "../styles/MultySelectStyle";
import { ClearData } from '../store/existingDomain/existingDomain.actions';
import fetchData from '../hook/fetchData';
import { getEnv } from '@urecruits/api';
const {API_RECRUITMENT}= getEnv()

const stepOne = require("./../images/stepOne.svg");
const stepTwo = require("./../images/stepTwo.svg");
const cross = require("./../images/icon/cross.svg");
const emptyLine = require("./../images/emptyLine.svg");
const firstLine = require("./../images/firstLine.svg");
const upIcon = require("./../images/dropDownSelected.svg");
const downIcon = require("./../images/dropDownIconCommon.svg");

const StepOne = () => {
    const [positionIndustryList, setPositionIndustryList] = useState([]);
    const [departmentList, setDepartmentList] = useState([]);
    const dispatch = useDispatch();
    const { name, industry, department,editAction } = useSelector(domainSelectors.getAllState);

    const unlock = name ? '' : 'disable';

    const nextStep = () => {
        if (name) {
            dispatch(SetStep(2))
        }
    }

    const DropdownIndicator = props => {
        return (
            components.DropdownIndicator && (
                <components.DropdownIndicator {...props}>
                    <img src={props.selectProps.menuIsOpen ? upIcon : downIcon} alt="up" />
                </components.DropdownIndicator>
            )
        );
    };

    const handleChangeSelect = (selected, type) => {
        if (type === 'industry') {
            dispatch(SetIndustry(selected.id));
        }
        if (type === 'department') {
            dispatch(SetDepartment(selected.id));
        }
    }
    const handleCancel=()=>{
        dispatch(ClearData(0));
        dispatch(ClearQuestion(''));
    }
    
    useEffect(() => {
        (async () => {
            try {
                fetchData(`${API_RECRUITMENT}/api/industry/label`,setPositionIndustryList);
                fetchData(`${API_RECRUITMENT}/api/department/label`,setDepartmentList)
            } catch (e) {
                console.error('Fetch error', e)
            }
        })();
    }, []);

    return (
        <div className="domain-modal">
            <div
                className="domain-modal__container"
                onClick={(e) => e.stopPropagation()}
            >
                <div className='domain-modal__head__wrapper'>
                    <div className="domain-modal__head">
                        <h1 className="domain-modal__head__title">
                          {editAction?.action==="edit" ? "Edit" : "Add New"} Domain Assessment
                        </h1>
                        <button
                            className="domain-modal__head__cross-ico"
                            onClick={() => {
                                handleCancel()
                                navigate("/assessment/domain-assessment")
                            }}
                        >
                            <img src={cross} alt="cross-icon" />
                        </button>
                    </div>
                    <div className='domain-modal__circle__wrapper'>
                        <img className='domain-modal__circle' src={stepOne} alt="circle" />
                        <img className='domain-modal__line' src={firstLine} alt="line" />
                        <img className='domain-modal__circle' src={stepTwo} alt="circle" />
                        <img src={emptyLine} alt="line" />
                        <img className='domain-modal__circle' src={stepTwo} alt="circle" />
                    </div>
                    <div className='domain-modal__subtitle__wrapper'>
                        <span className='domain-modal__subtitle'>
                            Assessment info
                        </span>
                        <span className='domain-modal__subtitle not-active mg-left'>
                            Questions
                        </span>
                        <span className='domain-modal__subtitle not-active'>
                            Time & Score
                        </span>
                    </div>
                </div>
                <div className="domain-modal__container__wrapper">
                    <div className="domain-modal__select mgTop">
                        <label className="label-name-font" htmlFor="label-name-font">
                            <span>
                                Assessment Name<span className="domain-modal__required">*</span>
                            </span>
                        </label>
                        <input
                            className='live-task-input'
                            type="text"
                            value={name}
                            placeholder="Enter question title"
                            onChange={(e) => {
                                dispatch(SetName(e.target.value))
                            }}
                        />
                    </div>
                    <div className="domain-modal__select">
                        <span className='domain-modal__label'>Industry</span>
                        <MultySelect
                            openMenuOnClick={true}
                            options={positionIndustryList}
                            menuPlacement='bottom'
                            placeholder='Choose the industry'
                            hideSelectedOptions={false}
                            components={{ DropdownIndicator, IndicatorSeparator: () => null }}
                            onChange={e => handleChangeSelect(e, 'industry')}
                            value={positionIndustryList.find(item => item.id === industry) as object}
                            styles={MultySelectStyle}
                        />
                    </div>
                    <div className="domain-modal__select">
                        <span className='domain-modal__label'>Department</span>
                        <MultySelect
                            openMenuOnClick={true}
                            options={departmentList}
                            menuPlacement='bottom'
                            placeholder='Choose the department'
                            hideSelectedOptions={false}
                            components={{ DropdownIndicator, IndicatorSeparator: () => null }}
                            onChange={e => handleChangeSelect(e, 'department')}
                            value={departmentList.find(item => item.id === department) as object}
                            styles={MultySelectStyle}
                        />
                    </div>
                </div>

                <div className="domain-modal__footer">
                    <div
                        className="domain-modal__footer__container"
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "end",
                        }}
                    >
                        <button
                            className="domain-modal__footer__container__cancel"
                            onClick={() => {
                                if(localStorage.getItem("prevRoute")==="/recruitment/position-workflow/create")
                                { 
                                  localStorage.setItem("prevRoute","")
                                  handleCancel()
                                  navigate("/recruitment/position-workflow/create");
                                }else{
                                  handleCancel()
                                  navigate("/assessment/domain-assessment");
                                }}}
                        >
                            Cancel
                        </button>
                        <button
                            disabled={unlock === 'disable' ? true : false}
                            className={`domain-modal__footer__container__save ${unlock}`}
                            onClick={() => nextStep()}
                        >
                            Next
                        </button>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default StepOne;
