import { combineReducers } from "redux";
import domain from "./domain/domain.reducer";
import modal from "./modal/modal.reducer";
import existingDomain from "./existingDomain/existingDomain.reducers";
import * as types from "../types";

export interface RootState {
  domain: types.domain.State;
  modal: types.modal.State;
  existingDomain: types.existingDomain.State;
}

export interface RootAction {
  type: "RESET_APP";
}

const appReducer = combineReducers<RootState>({
  domain,
  modal,
  existingDomain
});

const rootReducer = (
  state: RootState | undefined,
  action: RootAction
): RootState => {
  if (action.type === "RESET_APP") {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
