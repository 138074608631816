export enum actionsTypes {
    SHOW_MODAL = "modal/SHOW_MODAL",
    HIDE_MODAL = "modal/HIDE_MODAL",
    SHOW_DIALOG = "modal/SHOW_DIALOG",
    HIDE_DIALOG = "modal/HIDE_DIALOG",
    SET_TEXT = "modal/SET_TEXT",
    SET_POINT = "modal/SET_POINT",
}

export type Actions =
    | HideModal
    | ShowModal
    | SetText
    | SetPoint

export interface HideModal {
    type: actionsTypes.HIDE_MODAL;
    payload: boolean;
}

export interface ShowModal {
    type: actionsTypes.SHOW_MODAL;
    payload: boolean;
}

export interface HideDialog {
    type: actionsTypes.HIDE_DIALOG;
    payload: boolean;
}

export interface ShowDialog {
    type: actionsTypes.SHOW_DIALOG;
    payload: boolean;
}

export interface SetText {
    type: actionsTypes.SET_TEXT;
    payload: string;
}

export interface SetPoint {
    type: actionsTypes.SET_POINT;
    payload: number;
}