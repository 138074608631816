import { existingDomain as types } from "../../types";
import produce from "immer";

export const initialValue: types.State = {
    data: [],
    addData: [],
    allScore: 0,
};

const reducer = (state = initialValue, action: any) => {
    switch (action.type) {
        case types.actionsTypes.SET_DATA:
            return produce(state, (draft) => {
                const ids = draft.addData.map(item => item.id);

                if (ids && ids.length) {
                    const filterData = action.payload.filter(item => !ids.includes(item.id))
                    draft.data = filterData;
                } else {
                    draft.data = action.payload
                }
            });

        case types.actionsTypes.MOVE_QUESTION:
            return produce(state, (draft) => {
                const { id } = action.payload[0];
                const filterQuestion = draft.data.filter(item => item.id !== id);
                draft.data = filterQuestion;
            });

        case types.actionsTypes.ADD_EXISTING_QUESTION:
            return produce(state, (draft) => {
                const { item } = action.payload[0];
                draft.addData.push(item);
            });

        case types.actionsTypes.EDIT_EXISTING_QUESTION:
            return produce(state, (draft) => {
                const { indexAddData } = action.payload[0];
                const changeStatus = !draft.addData[indexAddData].isEditQuestion;
                draft.addData[indexAddData].isEditQuestion = changeStatus;
            });

        case types.actionsTypes.DELETE_EXISTING_QUESTION:
            return produce(state, (draft) => {
                const { id } = action.payload[0];
                const findItem = draft.addData.find(item => item.id === id)
                const filterQuestion = draft.addData.filter(item => item.id !== id);

                draft.addData = filterQuestion;
                draft.data.push(findItem);
            });

        case types.actionsTypes.SET_QUESTION_NAME:
            return produce(state, (draft) => {
                const { name, index, indexAddData } = action.payload[0];
                draft.addData[indexAddData].questions[index].name = name;
            });
        case types.actionsTypes.SET_QUESTION_TYPE:
            return produce(state, (draft) => {
                const { type, index, answers, indexAddData } = action.payload[0];
                if (type) {
                    draft.addData[indexAddData].questions[index].type = type;
                    draft.addData[indexAddData].questions[index].answers = answers;
                    draft.addData[indexAddData].questions[index].score = 0;
                }
            });
        case types.actionsTypes.SET_OPTION_NAME:
            return produce(state, (draft) => {
                const { value, id, element, index, indexAddData } = action.payload[0];

                draft.addData[indexAddData].questions[index].answers[element].name = value;
            });

        case types.actionsTypes.SET_EDIT_POINT:
            return produce(state, (draft) => {
                const { index, value, indexAddData } = action.payload[0];
                draft.addData[indexAddData].questions[index].isEditPoint = value;
            });

        case types.actionsTypes.SET_POINT:
            return produce(state, (draft) => {
                const { value, index, type, element, indexAddData } = action.payload[0];
                if (type !== 'multiple') {
                    draft.addData[indexAddData].questions[index].score = value;
                } else {
                    const sumScore = draft.addData[indexAddData].questions[index].answers.reduce((previousValue, currentValue) => previousValue + currentValue.score, 0);
                    const dif = value - draft.addData[indexAddData].questions[index].answers[element].score;
                    if (sumScore + dif <= 10) {
                        draft.addData[indexAddData].questions[index].answers[element].score = value;
                        draft.addData[indexAddData].questions[index].score = sumScore + dif;
                    }
                }
            });

        case types.actionsTypes.SET_CORRECT_ANSWER:
            return produce(state, (draft) => {

                const { index, id, value, type, indexAddData } = action.payload[0];
                if (type === 'radio') {
                    draft.addData[indexAddData].questions[index].answers.find(item => {
                        if (item.isCorrect === true) {
                            item.isCorrect = false
                        }
                    });
                    draft.addData[indexAddData].questions[index].answers[id].isCorrect = true;
                }
                if (type === 'checkbox') {
                    draft.addData[indexAddData].questions[index].answers[id].isCorrect = !draft.addData[indexAddData].questions[index].answers[id].isCorrect;
                }
                if (type === 'text') {
                    draft.addData[indexAddData].questions[index].answers = value;
                }

            });

        case types.actionsTypes.DELETE_ANSWER:
            return produce(state, (draft) => {
                const { id, indexArray, indexAddData } = action.payload[0];
                const filterAnswer = draft.addData[indexAddData].questions[indexArray].answers.filter(item => item.id !== id)
                draft.addData[indexAddData].questions[indexArray].answers = filterAnswer;
            });

        case types.actionsTypes.ADD_ANSWER:
            return produce(state, (draft) => {
                const { index, answerData, indexAddData } = action.payload[0];
                draft.addData[indexAddData].questions[index].answers.push(answerData)
            });

        case types.actionsTypes.DELETE_QUESTION:
            return produce(state, (draft) => {
                const { id, indexAddData } = action.payload[0];

                const filterQuestion = draft.addData[indexAddData].questions.filter(item => item.id !== id);
                draft.addData[indexAddData].questions = filterQuestion;
            });

        case types.actionsTypes.SET_SCORE:
            return produce(state, (draft) => {
                draft.allScore = action.payload;
            });

        case types.actionsTypes.CLEAR_DATA:
            return produce(state, (draft) => {
                draft.allScore = 0;
                draft.data = [];
                draft.addData = [];
            });

        default:
            return state;
    }
};

export default reducer;
