import axios from "axios";

const putData = async (api, data) => {
  const token: string | null = localStorage.getItem("token");
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  return await axios.patch(api, data, config);
};

export default putData;
