import React from "react";
import { default as ReactSelect } from "react-select";

export interface Option {
    value: string;
    label: string;
    id?: number;
    name?: string;
}

interface CustomSelectProps {
    options?: Option[];
    placeholder?: string;
    isMulti?: boolean;
    closeMenuOnSelect?: boolean;
    hideSelectedOptions?: boolean;
    components?: any;
    onChange: any;
    allowSelectAll?: boolean;
    value: any;
    allOption: any;
    openMenuOnClick?: boolean;
    styles?: any;
    menuPlacement?: any;
}

const MySelect = (props: CustomSelectProps) => {
    if (props.allowSelectAll) {
        return (
            <ReactSelect
                {...props}
                options={[props.allOption, ...props.options]}
                onChange={selected => {
                    if (
                        selected !== null &&
                        selected.length > 0 &&
                        selected[selected.length - 1].value === props.allOption.value
                    ) {
                        return props.onChange(props.options);
                    }
                    return props.onChange(selected);
                }}
            />
        );
    }

    return <ReactSelect {...props} />;
};


MySelect.defaultProps = {
    allOption: {
        label: "Select all reviewers",
        value: "*"
    }
};

export default MySelect;