import React, { Fragment } from 'react'

import { existingDomain as types } from "../types";

const ExistingAnswer = (props: types.AnswerExist) => {
    const { isOpen, item, index } = props;

    const optionHandler = (questions, index) => {
        if (questions.type === 'single') {
            return questions.answers.map((answer => {
                return (
                    <Fragment key={answer.id}>
                        <input type="radio" id={`${answer.id}`} className="radio-input" name="radio-group" checked={false}
                            onChange={e => null}
                        />
                        <label htmlFor={`${answer.id}`} className='radio-label mg-bottom' >
                            <span className='radio-border'></span>
                            {answer.name}
                        </label>
                    </Fragment>
                )
            }))

        } else if (questions.type === 'multiple') {
            return questions.answers.map((answer => {
                return (
                    <Fragment key={answer.id}>
                        <label className="checkbox__wrapper">
                            <div className="checkbox__input mg-bottom">
                                <input type="checkbox" className="checkbox-checked"
                                    disabled={true} checked={false}
                                />
                                {<span>{answer.name}</span>}
                            </div>
                        </label>
                    </Fragment>
                )
            }))
        } else if (questions.type === 'text') {
            return (
                <div className='radio__wrapper' key={index}>
                    <textarea
                        value={questions.answers}
                        className="textarea-style"
                        id="w3review"
                        name="w3review"
                        rows={4}
                        placeholder='Type answer'
                        disabled={true}
                    />
                </div>)
        }
    }

    return (
        <>
            {
                isOpen &&
                <Fragment key={index}>
                    <span className='existing__content' >
                        {item.name}
                    </span>
                    {optionHandler(item, index)}
                </Fragment>
            }
        </>
    )
};

export default ExistingAnswer;