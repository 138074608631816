import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { modalState } from "../store/modal/modal.selectors";
import Modal from "../components/Modal";
import Dialog from "../components/Dialog";
import { useParams } from "react-router-dom";
import { SetDepartment, SetEditAction, SetIndustry, SetName, SetPassingScore, SetQuestion, SetTimeDuration } from "../store/domain/domain.actions";
import fetchData from "../hook/fetchData";
import { getEnv } from "@urecruits/api";
import { useHasPermission, AuthGuard } from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";
const { API_ASSESSMENT } = getEnv()


const Home = (props) => {

    const { checkUserPermission } = useHasPermission()
    const dispatch = useDispatch();
    const [data, setData] = useState(null)
    const { action, id } = useParams()
    useEffect(() => {
        if (action && id) {
            dispatch(SetEditAction({
                id, action
            }))
            const answerArray = []
            checkUserPermission('assessment', 'view') && fetchData(`${API_ASSESSMENT}/api/domain-questions/${id}`, setData)
                .then((res) => {
                    const item = res?.data
                    dispatch(SetName(item?.name))
                    dispatch(SetIndustry(item?.industryId));
                    dispatch(SetDepartment(item?.departmentId));
                    item?.questions?.map((que) => {
                        let answer = que?.type !== "text" ? JSON.parse(que?.answers) : que?.answers

                        const questionData = {
                            id: que?.id,
                            isEditPoint: false,
                            name: que?.name,
                            type: que?.type,
                            answers: que?.type !== "text" ? answer?.map((aa) => {
                                return aa
                            }) : answer,
                            score: que?.score,
                        }
                        answerArray?.push(questionData)
                    })
                    dispatch(SetQuestion(answerArray))
                    dispatch(SetTimeDuration(item?.duration))
                    dispatch(SetPassingScore([item?.passing]))
                })
        }
    }, [])

    const { isModalOpen, isDialogOpen } = useSelector(modalState.getAllState);
    const element = document.getElementsByTagName('html');
    useEffect(() => {
        if (element && element[0] && element[0].classList) {
            element[0].classList.remove('theme-dark')
        }
    }, [element])
    return (
        <div
            style={{
                display: "flex",
                width: "100vw",
                justifyContent: "center",
                marginTop: "20vh",
            }}
        >
            <AuthGuard module='assessment' permission={['add', 'edit']}>
                <Modal />
            </AuthGuard>
            {
                isDialogOpen && (
                    <Dialog />
                )
            }

        </div>
    );
};

export default Home;