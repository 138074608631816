import { domain } from "../../types";
import { RootState } from "../root-reducer";

const getAllState = (state: RootState): domain.State => {
  return state.domain;
};

export const domainSelectors = {
  getAllState,
};
