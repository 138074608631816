import { modal as types } from "../../types";
import * as actions from "../../types/modal/actions.types";

export const HideModal = (
    payload: types.State["isModalOpen"]
): actions.HideModal => ({
    type: types.actionsTypes.HIDE_MODAL,
    payload,
});

export const ShowModal = (
    payload: types.State["isModalOpen"]
): actions.ShowModal => ({
    type: types.actionsTypes.SHOW_MODAL,
    payload,
});

export const HideDialog = (
    payload: types.State["isDialogOpen"]
): actions.HideDialog => ({
    type: types.actionsTypes.HIDE_DIALOG,
    payload,
});

export const ShowDialog = (
    payload: types.State["isDialogOpen"]
): actions.ShowDialog => ({
    type: types.actionsTypes.SHOW_DIALOG,
    payload,
});

export const SetText = (
    payload: types.State["text"]
): actions.SetText => ({
    type: types.actionsTypes.SET_TEXT,
    payload,
});

export const SetPoint = (
    payload: types.State["point"]
): actions.SetPoint => ({
    type: types.actionsTypes.SET_POINT,
    payload,
});