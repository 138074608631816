import { domain as types } from "../../types";
import * as actions from "../../types/domain/actions.types";

export const SetName = (
  payload: types.State["name"]
): actions.SetName => ({
  type: types.actionsTypes.SET_NAME,
  payload,
});

export const SetIndustry = (
  payload: types.State["industry"]
): actions.SetIndustry => ({
  type: types.actionsTypes.SET_INDUSTRY,
  payload,
});

export const SetDepartment = (
  payload: types.State["department"]
): actions.SetDepartment => ({
  type: types.actionsTypes.SET_DEPARTMENT,
  payload,
});

export const SetTimeDuration = (
  payload: types.State["duration"]
): actions.SetTimeDuration => ({
  type: types.actionsTypes.SET_TIME_DURATION,
  payload,
});

export const SetPassingScore = (
  payload: types.State["passing"]
): actions.SetPassingScore => ({
  type: types.actionsTypes.SET_PASSING_SCORE,
  payload,
});

export const SetStep = (
  payload: types.State["step"]
): actions.SetStep => ({
  type: types.actionsTypes.SET_STEP,
  payload,
});

export const AddQuestion = (
  payload: types.State["questions"]
): actions.AddQuestion => ({
  type: types.actionsTypes.ADD_QUESTION,
  payload,
});

export const SetQuestion = (
  payload: types.State["questions"]
): actions.SetQuestion => ({
  type: types.actionsTypes.SET_QUESTION,
  payload,
});

export const DeleteQuestion = (
  payload: types.State["questions"]
): actions.DeleteQuestion => ({
  type: types.actionsTypes.DELETE_QUESTION,
  payload,
});

export const SetQuestionName = (
  payload: types.State["questions"]
): actions.SetQuestionName => ({
  type: types.actionsTypes.SET_QUESTION_NAME,
  payload,
});

export const SetQuestionType = (
  payload: types.State["questions"]
): actions.SetQuestionType => ({
  type: types.actionsTypes.SET_QUESTION_TYPE,
  payload,
});

export const SetQuestionScore = (
  payload: types.State["questions"]
): actions.SetQuestionScore => ({
  type: types.actionsTypes.SET_QUESTION_SCORE,
  payload,
});

export const SetEditPoint = (
  payload: types.State["questions"]
): actions.SetEditPoint => ({
  type: types.actionsTypes.SET_EDIT_POINT,
  payload,
});

export const SetPoint = (
  payload: types.State["questions"]
): actions.SetPoint => ({
  type: types.actionsTypes.SET_POINT,
  payload,
});

export const SetCorrectAnswer = (
  payload: types.State["questions"]
): actions.SetCorrectAnswer => ({
  type: types.actionsTypes.SET_CORRECT_ANSWER,
  payload,
});

export const DeleteAnswer = (
  payload: types.State["questions"]
): actions.DeleteAnswer => ({
  type: types.actionsTypes.DELETE_ANSWER,
  payload,
});

export const AddAnswer = (
  payload: types.State["questions"]
): actions.AddAnswer => ({
  type: types.actionsTypes.ADD_ANSWER,
  payload,
});

export const SetOptionName = (
  payload: types.State["questions"]
): actions.SetOptionName => ({
  type: types.actionsTypes.SET_OPTION_NAME,
  payload,
});

export const ClearQuestion = (
  payload: types.State["name"]
): actions.ClearQuestion => ({
  type: types.actionsTypes.CLEAR_QUESTION,
  payload,
});

export const SetEditAction = (
  payload: types.State["editAction"]
): actions.SetEditAction => ({
  type: types.actionsTypes.SET_EDIT_ACTION,
  payload,
});

