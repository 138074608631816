import React from 'react'
import { useSelector } from "react-redux";

import { domainSelectors } from "../store/domain/domain.selectors";

import StepOne from "./StepOne";
import StepTwo from "./StepTwo";
import StepThree from "./StepThree";



function Modal() {
    const { step } = useSelector(
        domainSelectors.getAllState
    );

    const currentStep = (step) => {
        if (step === 2) {
            return <StepTwo />
        } else if (step === 3) {
            return <StepThree />
        }
        return <StepOne />

    }

    return (
        currentStep(step)
    );
}

export default Modal;
