import { domain as types } from "../../types";
import produce from "immer";

import { uuidv4 } from "../../config/utils";

export const initialValue: types.State = {
  name: "",
  duration: "",
  passing: [1],
  industry: null,
  department: null,
  step: 1,
  questions: [
    {
      id: uuidv4(),
      isEditPoint: false,
      name: '1. How much experience do you have in systems analysis?',
      type: 'single',
      answers: [{ "id": uuidv4(), "isCorrect": false, "score": 0, "name": "1 year" }, { "id": uuidv4(), "isCorrect": false, "score": 0, "name": "1-3 years" }],
      score: 0,
    }
  ],
  editAction:{
    id:null,
    action:""
  },
};

const reducer = (state = initialValue, action: any) => {
  switch (action.type) {
    case types.actionsTypes.SET_NAME:
      return produce(state, (draft) => {
        draft.name = action.payload;
      });

    case types.actionsTypes.SET_INDUSTRY:
      return produce(state, (draft) => {
        draft.industry = action.payload;
      });

    case types.actionsTypes.SET_EDIT_ACTION:
      return produce(state, (draft) => {
        draft.editAction = action.payload;
      });

    case types.actionsTypes.SET_DEPARTMENT:
      return produce(state, (draft) => {
        draft.department = action.payload;
      });

    case types.actionsTypes.SET_TIME_DURATION:
      return produce(state, (draft) => {
        draft.duration = action.payload;
      });

    case types.actionsTypes.SET_PASSING_SCORE:
      return produce(state, (draft) => {
        draft.passing = action.payload;
      });

    case types.actionsTypes.SET_STEP:
      return produce(state, (draft) => {
        draft.step = action.payload;
      });

    case types.actionsTypes.ADD_QUESTION:
      return produce(state, (draft) => {
        const { questionData } = action.payload[0];
        draft.questions.push(questionData);
      });

    case types.actionsTypes.SET_QUESTION:
      return produce(state, (draft) => {
        draft.questions=(action.payload);
      });


    case types.actionsTypes.SET_QUESTION_NAME:
      return produce(state, (draft) => {
        const { name, index } = action.payload[0];
        draft.questions[index].name = name;
      });
    case types.actionsTypes.SET_QUESTION_TYPE:
      return produce(state, (draft) => {
        const { type, index, answers } = action.payload[0];
        if (type) {
          draft.questions[index].type = type;
          draft.questions[index].answers = answers;
          draft.questions[index].score = 0;
        }
      });
    case types.actionsTypes.SET_OPTION_NAME:
      return produce(state, (draft) => {
        const { value, id, element, index } = action.payload[0];
        draft.questions[index].answers[element].name = value;
      });

    case types.actionsTypes.SET_QUESTION_SCORE:
      return produce(state, (draft) => {
        const { score, index } = action.payload[0];
        if (score) {
          draft.questions[index].score = score;
        }
      });

    case types.actionsTypes.SET_EDIT_POINT:
      return produce(state, (draft) => {
        const { index, value } = action.payload[0];
        draft.questions[index].isEditPoint = value;
      });

    case types.actionsTypes.SET_POINT:
      return produce(state, (draft) => {
        const { value, index, type, element } = action.payload[0];
        if (type !== 'multiple') {
          draft.questions[index].score = value;
        } else {
          const sumScore = draft.questions[index].answers.reduce((previousValue, currentValue) => previousValue + currentValue.score, 0);
          const dif = value - draft.questions[index].answers[element].score;
          if (sumScore + dif <= 10) {
            draft.questions[index].answers[element].score = value;
            draft.questions[index].score = sumScore + dif;
          }
        }
      });

    case types.actionsTypes.SET_CORRECT_ANSWER:
      return produce(state, (draft) => {

        const { index, id, value, type } = action.payload[0];
        if (type === 'radio') {
          draft.questions[index].answers.find(item => {
            if (item.isCorrect === true) {
              item.isCorrect = false
            }
          });
          draft.questions[index].answers[id].isCorrect = true;
        }
        if (type === 'checkbox') {
          draft.questions[index].answers[id].isCorrect = !draft.questions[index].answers[id].isCorrect;
        }
        if (type === 'text') {
          draft.questions[index].answers = value;
        }

      });

    case types.actionsTypes.DELETE_ANSWER:
      return produce(state, (draft) => {
        const { id, indexArray } = action.payload[0];
        const filterAnswer = draft.questions[indexArray].answers.filter(item => item.id !== id)
        draft.questions[indexArray].answers = filterAnswer;
      });

    case types.actionsTypes.ADD_ANSWER:
      return produce(state, (draft) => {
        const { index, answerData } = action.payload[0];
        draft.questions[index].answers.push(answerData)
      });

    case types.actionsTypes.DELETE_QUESTION:
      return produce(state, (draft) => {
        const { id } = action.payload[0];

        const filterQuestion = draft.questions.filter(item => item.id !== id);
        draft.questions = filterQuestion;
      });

    case types.actionsTypes.CLEAR_QUESTION:
      return produce(state, (draft) => {
        draft.questions = [
          {
            id: uuidv4(),
            isEditPoint: false,
            name: '1. How much experience do you have in systems analysis?',
            type: 'single',
            answers: [{ "id": uuidv4(), "isCorrect": false, "score": 0, "name": "1 year" }, { "id": uuidv4(), "isCorrect": false, "score": 0, "name": "1-3 years" }],
            score: 0,
          }
        ];
        draft.name = '';
        draft.duration = "";
        draft.passing = [1];
        draft.industry = '';
        draft.department = '';
        draft.step = 1;
      });

    default:
      return state;
  }
};

export default reducer;
