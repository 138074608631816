export enum actionsTypes {
  SET_NAME = "domain/SET_NAME",
  SET_INDUSTRY = "domain/SET_INDUSTRY",
  SET_DEPARTMENT = "domain/SET_DEPARTMENT",
  SET_TIME_DURATION = "domain/SET_TIME_DURATION",
  SET_PASSING_SCORE = "domain/SET_PASSING_SCORE",
  SET_STEP = "domain/SET_STEP",
  ADD_QUESTION = "domain/ADD_QUESTION",
  SET_QUESTION_NAME = "domain/SET_QUESTION_NAME",
  SET_QUESTION_TYPE = "domain/SET_QUESTION_TYPE",
  SET_QUESTION_SCORE = "domain/SET_QUESTION_SCORE",
  SET_EDIT_POINT = "domain/SET_EDIT_POINT",
  SET_POINT = "domain/SET_POINT",
  SET_CORRECT_ANSWER = "domain/SET_CORRECT_ANSWER",
  DELETE_ANSWER = "domain/DELETE_ANSWER",
  DELETE_QUESTION = "domain/DELETE_QUESTION",
  ADD_ANSWER = "domain/ADD_ANSWER",
  SET_OPTION_NAME = "domain/SET_OPTION_NAME",
  CLEAR_QUESTION = "domain/CLEAR_QUESTION",
  SET_EDIT_ACTION = "domain/SET_EDIT_ACTION",
  SET_QUESTION = "domain/SET_QUESTION",
}

export type Actions =
  | SetName
  | SetIndustry
  | SetDepartment
  | SetTimeDuration
  | SetPassingScore
  | SetStep
  | AddQuestion
  | SetQuestionName
  | SetQuestionType
  | SetQuestionScore
  | SetEditPoint
  | SetCorrectAnswer
  | DeleteAnswer
  | SetOptionName
  | SetPoint
  | AddAnswer
  | DeleteQuestion
  | ClearQuestion
  | SetEditAction

export interface SetName {
  type: actionsTypes.SET_NAME;
  payload: string;
}

export interface SetIndustry {
  type: actionsTypes.SET_INDUSTRY;
  payload: string;
}

export interface SetDepartment {
  type: actionsTypes.SET_DEPARTMENT;
  payload: string;
}
export interface SetTimeDuration {
  type: actionsTypes.SET_TIME_DURATION;
  payload: string;
}

export interface SetPassingScore {
  type: actionsTypes.SET_PASSING_SCORE;
  payload: Array<number>;
}

export interface SetStep {
  type: actionsTypes.SET_STEP;
  payload: number;
}

export interface AddQuestion {
  type: actionsTypes.ADD_QUESTION;
  payload: Array<object>;
}

export interface SetQuestion {
  type: actionsTypes.SET_QUESTION;
  payload: Array<object>;
}

export interface DeleteQuestion {
  type: actionsTypes.DELETE_QUESTION;
  payload: Array<object>;
}

export interface SetQuestionName {
  type: actionsTypes.SET_QUESTION_NAME;
  payload: any;
}

export interface SetQuestionType {
  type: actionsTypes.SET_QUESTION_TYPE;
  payload: object;
}

export interface SetQuestionScore {
  type: actionsTypes.SET_QUESTION_SCORE;
  payload: Array<object>;
}

export interface SetEditPoint {
  type: actionsTypes.SET_EDIT_POINT;
  payload: Array<object>;
}

export interface SetPoint {
  type: actionsTypes.SET_POINT;
  payload: Array<object>;
}

export interface SetCorrectAnswer {
  type: actionsTypes.SET_CORRECT_ANSWER;
  payload: Array<object>;
}

export interface DeleteAnswer {
  type: actionsTypes.DELETE_ANSWER;
  payload: Array<object>;
}

export interface AddAnswer {
  type: actionsTypes.ADD_ANSWER;
  payload: Array<object>;
}

export interface SetOptionName {
  type: actionsTypes.SET_OPTION_NAME;
  payload: Array<object>;
}

export interface ClearQuestion {
  type: actionsTypes.CLEAR_QUESTION;
  payload: string;
}

export interface SetEditAction {
  type: actionsTypes.SET_EDIT_ACTION;
  payload: any;
}

