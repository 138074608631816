export enum actionsTypes {
    SET_DATA = "existingDomain/SET_DATA",
    MOVE_QUESTION = "existingDomain/MOVE_QUESTION",
    ADD_EXISTING_QUESTION = "existingDomain/ADD_EXISTING_QUESTION",
    DELETE_EXISTING_QUESTION = "existingDomain/DELETE_EXISTING_QUESTION",
    EDIT_EXISTING_QUESTION = "existingDomain/EDIT_EXISTING_QUESTION",
    ADD_QUESTION = "existingDomain/ADD_QUESTION",
    SET_QUESTION_NAME = "existingDomain/SET_QUESTION_NAME",
    SET_QUESTION_TYPE = "existingDomain/SET_QUESTION_TYPE",
    SET_QUESTION_ANSWER = "existingDomain/SET_QUESTION_ANSWER",
    SET_EDIT_POINT = "existingDomain/SET_EDIT_POINT",
    SET_POINT = "existingDomain/SET_POINT",
    SET_CORRECT_ANSWER = "existingDomain/SET_CORRECT_ANSWER",
    DELETE_ANSWER = "existingDomain/DELETE_ANSWER",
    DELETE_QUESTION = "existingDomain/DELETE_QUESTION",
    ADD_ANSWER = "existingDomain/ADD_ANSWER",
    SET_OPTION_NAME = "existingDomain/SET_OPTION_NAME",
    SET_SCORE = "existingDomain/SET_SCORE",
    CLEAR_DATA = "existingDomain/CLEAR_DATA",

}

export type Actions =
    | SetData
    | MoveQuestion
    | AddExistingQuestion
    | DeleteExistingQuestion
    | EditExistingQuestion
    | AddQuestion
    | SetQuestionName
    | SetQuestionType
    | SetEditPoint
    | SetCorrectAnswer
    | DeleteAnswer
    | SetOptionName
    | SetPoint
    | AddAnswer
    | DeleteQuestion
    | SetScore
    | ClearData

export interface AddQuestion {
    type: actionsTypes.ADD_QUESTION;
    payload: Array<object>;
}

export interface DeleteQuestion {
    type: actionsTypes.DELETE_QUESTION;
    payload: Array<object>;
}

export interface SetQuestionName {
    type: actionsTypes.SET_QUESTION_NAME;
    payload: any;
}

export interface SetQuestionType {
    type: actionsTypes.SET_QUESTION_TYPE;
    payload: object;
}

export interface SetEditPoint {
    type: actionsTypes.SET_EDIT_POINT;
    payload: Array<object>;
}

export interface SetPoint {
    type: actionsTypes.SET_POINT;
    payload: Array<object>;
}

export interface SetCorrectAnswer {
    type: actionsTypes.SET_CORRECT_ANSWER;
    payload: Array<object>;
}

export interface DeleteAnswer {
    type: actionsTypes.DELETE_ANSWER;
    payload: Array<object>;
}

export interface AddAnswer {
    type: actionsTypes.ADD_ANSWER;
    payload: Array<object>;
}

export interface SetOptionName {
    type: actionsTypes.SET_OPTION_NAME;
    payload: Array<object>;
}



export interface SetData {
    type: actionsTypes.SET_DATA;
    payload: Array<any>;
}

export interface MoveQuestion {
    type: actionsTypes.MOVE_QUESTION;
    payload: Array<any>;
}

export interface AddExistingQuestion {
    type: actionsTypes.ADD_EXISTING_QUESTION;
    payload: Array<any>;
}

export interface DeleteExistingQuestion {
    type: actionsTypes.DELETE_EXISTING_QUESTION;
    payload: Array<any>;
}

export interface EditExistingQuestion {
    type: actionsTypes.EDIT_EXISTING_QUESTION;
    payload: Array<any>;
}

export interface SetScore {
    type: actionsTypes.SET_SCORE;
    payload: number;
}

export interface ClearData {
    type: actionsTypes.CLEAR_DATA;
    payload: number;
}

