import { existingDomain } from "../../types";
import { RootState } from "../root-reducer";

const getAllState = (state: RootState): existingDomain.State => {
    return state.existingDomain;
};

export const existingDomainState = {
    getAllState,
};
