const departmentList = [
  { id: 1, value: "Department 1", label: "Department 1" },
  { id: 2, value: "Department 2", label: "Department 2" },
  { id: 3, value: "Department 3", label: "Department 3" },
  { id: 4, value: "Department 4", label: "Department 4" },
  { id: 5, value: "Department 5", label: "Department 5" },
  { id: 6, value: "Department 6", label: "Department 6" },
  { id: 7, value: "Department 7", label: "Department 7" },
  { id: 8, value: "Department 8", label: "Department 8" },
  { id: 9, value: "Department 9", label: "Department 9" },
  { id: 10, value: "Department 10", label: "Department 10" },
];

const assessmentTypeList = [
  { value: "Domain Assessment", label: "Domain Assessment" },
  { value: "Coding Assessment", label: "Coding Assessment" },
  { value: "Third Party Assessment", label: "Third Party Assessment" },
];

const timeDurationList = [
  { value: "15 min", label: "15 min", },
  { value: "30 min", label: "30 min", },
  { value: "1 Hr", label: "1 Hr", },
  { value: "1 Hr 30 min", label: "1 Hr 30 min", },
  { value: "2 Hrs 30 min", label: "2 Hrs 30 min", },
  { value: "3 Hrs", label: "3 Hrs", },
  { value: "3 Hrs 30 min", label: "3 Hrs 30 min", },
  { value: "4 Hrs", label: "4 Hrs", },
  { value: "4 Hrs 30 min", label: "4 Hrs 30 min", },
  { value: "5 Hrs", label: "5 Hrs", },
];

const typeQuestionList = [
  { value: "text", label: "Text field" },
  { value: "multiple", label: "Multiple choice" },
  { value: "single", label: "Single choice" }
];

export {
  assessmentTypeList,
  timeDurationList,
  departmentList,
  typeQuestionList
};