import { existingDomain as types } from "../../types";
import * as actions from "../../types/existingDomain/actions.types";

export const DeleteQuestion = (
    payload: types.State["addData"]
): actions.DeleteQuestion => ({
    type: types.actionsTypes.DELETE_QUESTION,
    payload,
});

export const SetQuestionName = (
    payload: types.State["addData"]
): actions.SetQuestionName => ({
    type: types.actionsTypes.SET_QUESTION_NAME,
    payload,
});

export const SetQuestionType = (
    payload: types.State["addData"]
): actions.SetQuestionType => ({
    type: types.actionsTypes.SET_QUESTION_TYPE,
    payload,
});

export const SetEditPoint = (
    payload: types.State["addData"]
): actions.SetEditPoint => ({
    type: types.actionsTypes.SET_EDIT_POINT,
    payload,
});

export const SetPoint = (
    payload: types.State["addData"]
): actions.SetPoint => ({
    type: types.actionsTypes.SET_POINT,
    payload,
});

export const SetCorrectAnswer = (
    payload: types.State["addData"]
): actions.SetCorrectAnswer => ({
    type: types.actionsTypes.SET_CORRECT_ANSWER,
    payload,
});

export const DeleteAnswer = (
    payload: types.State["addData"]
): actions.DeleteAnswer => ({
    type: types.actionsTypes.DELETE_ANSWER,
    payload,
});

export const AddAnswer = (
    payload: types.State["addData"]
): actions.AddAnswer => ({
    type: types.actionsTypes.ADD_ANSWER,
    payload,
});

export const SetOptionName = (
    payload: types.State["addData"]
): actions.SetOptionName => ({
    type: types.actionsTypes.SET_OPTION_NAME,
    payload,
});


export const SetData = (
    payload: types.State["data"]
): actions.SetData => ({
    type: types.actionsTypes.SET_DATA,
    payload,
});

export const MoveQuestion = (
    payload: types.State["data"]
): actions.MoveQuestion => ({
    type: types.actionsTypes.MOVE_QUESTION,
    payload,
});

export const AddExistingQuestion = (
    payload: types.State["addData"]
): actions.AddExistingQuestion => ({
    type: types.actionsTypes.ADD_EXISTING_QUESTION,
    payload,
});

export const DeleteExistingQuestion = (
    payload: types.State["addData"]
): actions.DeleteExistingQuestion => ({
    type: types.actionsTypes.DELETE_EXISTING_QUESTION,
    payload,
});

export const EditExistingQuestion = (
    payload: types.State["addData"]
): actions.EditExistingQuestion => ({
    type: types.actionsTypes.EDIT_EXISTING_QUESTION,
    payload,
});

export const SetScore = (
    payload: types.State["allScore"]
): actions.SetScore => ({
    type: types.actionsTypes.SET_SCORE,
    payload,
});

export const ClearData = (
    payload: types.State["allScore"]
): actions.ClearData => ({
    type: types.actionsTypes.CLEAR_DATA,
    payload,
});



